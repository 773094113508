import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.png";
import bannerbg from "../assets/img/banner-bg.png";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Fizjoterapia", "Fizjoterapia" ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h1>{`Klaudia Kachel`} <br /><span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Fizjoterapia", "Fizjoterapia" ]'><span className="wrap">{text}</span></span></h1>
                  <p>Specjalizuję się w diagnozie i leczeniu dysfunkcji miednicy mniejszej oraz wsparciu kobiet na każdym etapie życia. <br />Wierzę, że zdrowy styl życia, prawidłowa postawa i świadome nawyki to fundamenty dobrego samopoczucia i zdrowia każdej z moich pacjentek. <br /> Każda kobieta jest inna, dlatego stawiam na wnikliwą analizę potrzeb i objawów, aby stworzyć skuteczny,
indywidualny plan terapii. Współpracując razem, możemy osiągnąć Twoje cele zdrowotne, poprawić jakość życia i zadbać o dobre samopoczucie. <br />Razem zadbajmy o <b>Twoje</b> zdrowie i komfort życia!</p>
                  <span className="tagline">#ZdrowaFeminacja</span>
                  <button onClick={() => window.open('https://instagram.com/zdrowafeminacja/', '_blank')}> Umów się na konsultacje <ArrowRightCircle size={25} /> </button>
              </div>}
              
            </TrackVisibility>
            
          </Col>
          <Col xs={12} md={6} xl={5}>

{/*}          <TrackVisibility>
              {({ isVisible }) => 
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>*/}
                <div>
                  <img src={bannerbg} alt="banner-img" className="banner-img"/>
                </div>

          </Col>
        </Row>
      </Container>
    </section>
  )
}
